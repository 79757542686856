<template>
  <div>
    <bread-crumb
      v-if="actual_new"
      :selected="getTranslated(actual_new.translations, $i18n.locale, 'name')"
    ></bread-crumb>
    <section class="blog-news">
      <div
        class="blog-humor__descr"
        v-if="data"
        v-html="data.post_description"
      ></div>

      <h2 class="blog-news__title" v-if="data">{{ data.post_title }}</h2>

      <div class="blog-news__wrap" v-if="actual_new">
        <div class="blog-news__wrap--img">
          <div class="blog-news__wrap--img--inner">
            <v-lazy-image
              :src="url + actual_new.image"
              rel="preload"
              width="239"
              height="239"
              :alt="actual_new.name"
            />
          </div>
        </div>
        <div class="blog-news__wrap--descr">
          <h3
            class="blog-news__wrap--descr--title"
            v-html="
              getTranslated(
                actual_new.translations,
                $i18n.locale,
                'description'
              )
            "
          ></h3>
          <span class="blog-news__wrap--descr--date">{{
            formatDate(actual_new.updated_at)
          }}</span>
        </div>
      </div>

      <div
        class="blog-news__wrap--descr--text"
        v-if="actual_new"
        v-html="
          getTranslated(actual_new.translations, $i18n.locale, 'description_1')
        "
      ></div>

      <div class="blog-news__two-img" v-if="actual_new">
        <div class="blog-news__two-img--wrap">
          <div
            class="blog-news__two-img--wrap--inner"
            v-if="actual_new.image_1 != null"
          >
            <v-lazy-image
              :src="url + actual_new.image_1"
              rel="preload"
              width="720"
              height="532"
              alt="image-1"
            />
          </div>
        </div>
        <div class="blog-news__two-img--wrap">
          <div
            class="blog-news__two-img--wrap--inner"
            v-if="actual_new.image_2 != null"
          >
            <v-lazy-image
              :src="url + actual_new.image_2"
              rel="preload"
              width="455"
              height="532"
              alt="image-2"
            />
          </div>
        </div>
      </div>

      <div class="blog-news__wrap reverse" v-if="actual_new">
        <div class="blog-news__wrap--img">
          <div
            class="blog-news__wrap--img--inner"
            v-if="actual_new.image_3 != null"
          >
            <v-lazy-image
              :src="url + actual_new.image_3"
              rel="preload"
              width="720"
              height="532"
              alt="image-3"
            />
          </div>
        </div>
        <div class="blog-news__wrap--descr">
          <div
            class="blog-news__wrap--descr--text"
            v-if="actual_new"
            v-html="
              getTranslated(
                actual_new.translations,
                $i18n.locale,
                'description_2'
              )
            "
          ></div>
        </div>
      </div>

      <div
        class="blog-news__wrap--descr--text"
        v-if="actual_new"
        v-html="
          getTranslated(actual_new.translations, $i18n.locale, 'description_3')
        "
      ></div>

      <h3 class="blog-news__subtitle">{{ t("other_news") }}</h3>

      <div class="blog__news news">
        <article class="news__item" v-for="post in news" :key="post.id">
          <a :href="'/blog/' + post.slug">
            <div class="news__date">{{ formatDate(post.updated_at) }}</div>
            <div class="news__descr" v-html="post.description"></div>
          </a>
        </article>
        <div class="news__wrap">
          <button class="news__all" @click="getMoreNews()">
            {{ t("load_more") }}
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb")
);
import VLazyImage from "v-lazy-image";
import axios from "axios";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  props: {
    url: {
      type: String,
      default: process.env.VUE_APP_API,
    },
  },
  components: {
    BreadCrumb,
    VLazyImage,
  },
  data() {
    return {
      locale: this.$i18n.locale,
      actual_new: null,
      data: [],
      news: [],
      page: 1,
    };
  },
  methods: {
    getTranslated(transArray, locale, name) {
      let result = "";
      transArray.forEach((element) => {
        if (element.locale === locale) {
          result = element[name];
        }
      });
      return result;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
      };
      return new Intl.DateTimeFormat("uk-UA", options).format(date);
    },
    async getMoreNews() {
      try {
        this.page += 1;
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            page: this.page,
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/posts",
          config
        );
        this.news = [...this.news, ...response.data.data];
      } catch (e) {
        alert("Ошибка");
      }
    },
    async getNews() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/posts",
          config
        );
        this.news = response.data.data;
      } catch (e) {
        alert("Ошибка запроса");
      } finally {
        //что-то делаем после завершения
      }
    },
    async getActualNew() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/posts/search/" +
            this.$route.params.name,
          config
        );
        this.actual_new = response.data;
        document.title =
          this.getTranslated(response.data.translations, this.locale, "name") +
          " - iIT Trading";
      } catch (e) {
        alert("Ошибка запроса");
      } finally {
        //что-то делаем после завершения
      }
    },
    async getBlogPage() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/blog-page",
          config
        );
        this.data = response.data.data;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  created() {
    this.getBlogPage();
    this.getActualNew();
    this.getNews();
  },
  updated() {},
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.blog-news {
  &__title {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 42, 34);
    line-height: 1;
    color: #000000;
    text-transform: uppercase;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 60, 30);
  }

  &__descr {
    max-width: 800px;
    margin: 0 auto 1em;
    line-height: calc(25 / 16);

    pre {
      @media (max-width: 575.98px) {
        white-space: initial;
      }
    }
  }

  &__subtitle {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 32, 24);
    color: #000000;
    text-transform: uppercase;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 45, 20);
  }

  &__wrap {
    display: grid;
    @include property("gap", 65, 20, true, 1670, 1024);
    @include property("margin-bottom", 45, 15);
    justify-content: center;

    @media (min-width: 768px) {
      &:not(.reverse) {
        grid-template-columns: 1.5fr 1fr;
      }

      &.reverse {
        grid-template-columns: 1fr 1.5fr;
      }
    }

    &--img {
      background: url("~@/assets/images/bg-card.jpg") no-repeat;
      background-size: cover;
      width: 100%;
      align-self: flex-start;

      .reverse & {
        order: 2;
      }

      @media (max-width: 767.98px) {
        max-width: 500px;
        margin: 0 auto;
      }

      &--inner {
        position: relative;
        padding-bottom: 76%;
      }

      img {
        position: absolute;

        .blog-news__wrap:not(.reverse) & {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50%;
          height: 50%;
          max-height: 239px;
          max-width: 239px;
        }

        .reverse & {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &--descr {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &--title {
        font-weight: 700;
        @include property("font-size", 32, 24);
        letter-spacing: 0.64px;
        color: #f9b80e;
        margin-bottom: 0.7em;
      }

      &--text {
        @include property("font-size", 24, 20);
        letter-spacing: 0.48px;
        color: #1c1c1c;
      }

      &--date {
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: 18px;
        letter-spacing: 0.36px;
        color: #f9b80e;
      }
    }
  }

  &__two-img {
    display: grid;
    @include property("gap", 45, 20, true, 1670, 768);
    @include property("margin-bottom", 45, 20);

    @media (min-width: 768px) {
      grid-template-columns: 1.5fr 1fr;
    }

    &--wrap {
      &--inner {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #000000;
          opacity: 0.25;
          z-index: 3;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 2;
        }
      }

      &:nth-of-type(1) {
        .blog-news__two-img--wrap--inner {
          max-width: 720px;
          margin: 0 auto;
          padding-bottom: 74%;
        }
      }

      &:nth-of-type(2) {
        .blog-news__two-img--wrap--inner {
          max-width: 480px;
          margin: 0 auto;
          padding-bottom: 111%;
        }
      }
    }
  }

  &__look {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 630px;
    margin: 0 auto;
    @include property("margin-bottom", 100, 30);
    text-align: center;

    &--all {
      position: relative;
      text-align: center;
      @include property("font-size", 24, 20);
      line-height: 1.1;
      letter-spacing: 1.2px;
      color: #f9b80e;
      max-width: 175px;
      margin-top: 38px;
      @include property("margin-bottom", 45, 20);

      &::before {
        content: "";
        position: absolute;
        top: -15px;
        left: 50%;
        transform: translateX(-50%);
        width: 80px;
        height: 3px;
        background: #f9b80e;
        pointer-events: none;
      }
    }

    &--btn {
      text-align: center;
      font-weight: 700;
      letter-spacing: 0.8px;
      color: #ffffff;
      background: #f9b80e;
      box-shadow: 0px 0px 15px #f9b80e73;
      border: 1px solid #f9b80e;
      border-radius: 5px;
      padding: 10px 20px;
      margin-top: 20px;
      transition: all 0.3s linear;

      @media (hover: hover) {
        &:hover {
          background: #ff8c09;
          box-shadow: 0px 0px 15px #f9b80e80;
          border: 1px solid #ff8c09;
        }
      }
    }

    p {
      text-align: center;
      font-size: 20px;
      letter-spacing: 0.4px;
      color: #1c1c1c;
    }

    img {
      display: block;
      width: 49px;
    }
  }
}

.news {
  display: grid;
  @include property("grid-column-gap", 150, 40, true, 1366, 1024);
  @include property("grid-row-gap", 60, 20);
  @include property("margin-bottom", 100, 30);

  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  &__date {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 3px solid #f9b80e;
    letter-spacing: 0.8px;
    color: #f9b80e;
  }

  &__descr {
    line-height: calc(25 / 16);
    letter-spacing: 0.8px;
    color: #1c1c1c;
    margin: 0;
  }

  &__wrap {
    display: flex;
    justify-content: flex-end;
    @include property("margin-top", -30, -10);

    @media (min-width: 576px) {
      grid-column: 2 span;
    }
  }

  &__all {
    align-self: flex-end;
    line-height: calc(30 / 16);
    letter-spacing: 2.4px;
    color: #f9b80e;
  }
}
</style>
